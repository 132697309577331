import { Button, Paper, Stack, Tooltip, Typography } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import {
  EstimateUnits,
  Goal,
  GoalDay,
  GoalDayHostCheckIn,
  UserPrivate,
} from "@focusedspace/gql-api";
import { format } from "date-fns";
import { Color, boxShadowTop } from "../styles/SharedStyles";
import { CSSProperties, useState } from "react";
import { FocusedUser } from "../hooks/Users";

const LINE_BREAK = `%0D%0A`;

function formatDateWithDayOfWeek(utcDate: string) {
  return format(new Date(utcDate), "EEEE, MMMM do");
}
function formatGoalTitle(desc: string, goalNum: number) {
  return `${goalNum}: ${desc}`;
}
function formatFirstStep(firstStep: string) {
  return `First step: ${firstStep}`;
}
function formatEstimatedTime(value: number, unit: string) {
  return `Estimated time: ${value} ${unit}`;
}
function formatNotes(notes: string) {
  return `Notes: ${notes}`;
}
function formatSingleGoal(goal: Goal, goalNum: number) {
  let singleGoal = [formatGoalTitle(goal.description, goalNum)];

  if (goal.firstStep) {
    singleGoal.push(formatFirstStep(goal.firstStep));
  }
  if (goal.estimateValue && goal.estimateUnits) {
    singleGoal.push(
      formatEstimatedTime(goal.estimateValue, goal.estimateUnits)
    );
  }
  if (goal.notes) {
    singleGoal.push(formatNotes(goal.notes));
  }

  return singleGoal.join(LINE_BREAK);
}

function formatEmailBody(goals?: (Goal | null)[]) {
  if (!goals) {
    console.error("Error formatting email body from GoalDay Goals");
    return "";
  }

  const content: string[] = goals.map(
    (goal, idx) => `${formatSingleGoal(goal!, idx + 1)}`
  );

  const formattedContent = [LINE_BREAK, "---", ...content].join(LINE_BREAK);

  return formattedContent;
}

const generateMailToLink = (
  hostEmail: string,
  hostName: string,
  memberEmail: string,
  memberName: string,
  emailBody: string,
  date: string
) => {
  const from = `${encodeURIComponent(hostEmail)}`;
  const to = encodeURIComponent(`${memberEmail}?`);
  const formattedDate = date ? formatDateWithDayOfWeek(date) : "";
  const subject = encodeURIComponent(
    `subject=Check in: ${memberName}'s Goals for ${formattedDate} (Shared with ${hostName})`
  );
  const body = encodeURIComponent(`&body=${emailBody}`);

  return `https://mail.google.com/mail/a/focused.space${from}/?extsrc=mailto&url=mailto:${to}${subject}${body}`;
};

const renderGoal = (goal: Goal, idx: number) => {
  const formatTimeEstimate = (value: number, unit: EstimateUnits) => {
    if (unit && unit === EstimateUnits.HundredthHours) {
      const newVal = value / 100;
      return newVal + " Hour(s)";
    }
    return value + " " + unit;
  };

  return (
    goal && (
      <Typography color={Color.DarkGray} component="span" key={idx}>
        <Typography sx={{ fontWeight: 600 }}>
          {`${idx + 1}: ${goal.description}`}
        </Typography>
        {goal.firstStep && (
          <Typography>First step: {goal.firstStep}</Typography>
        )}
        {goal.createdAt && (
          <Typography>Starting at: {goal.createdAt}</Typography>
        )}
        {goal.estimateValue && goal.estimateUnits && (
          <Typography>
            Estimated time:{" "}
            {formatTimeEstimate(goal.estimateValue, goal.estimateUnits)}
          </Typography>
        )}
        {goal.notes && (
          <Typography sx={{ fontWeight: 600 }}>
            Notes:
            <Typography>{goal.notes}</Typography>
          </Typography>
        )}
      </Typography>
    )
  );
};

export function GoalDetail(props: {
  goalDay: GoalDay;
  hostData: FocusedUser;
  userData: UserPrivate;
  lastCheckIn?: GoalDayHostCheckIn;
  containerStyles?: CSSProperties;
  onCheckInChange: (changeValue: boolean) => void;
}) {
  const { goalDay, hostData, userData, lastCheckIn, onCheckInChange } = props;
  const goals = goalDay?.goals?.items;

  const [done, setDone] = useState(lastCheckIn?.done);

  const handleCheckInChange = () => {
    setDone(!done);
    onCheckInChange(!done);
  };

  const emailBody = formatEmailBody(goalDay?.goals?.items);
  const mailToLink = generateMailToLink(
    hostData.private.email || "",
    hostData.public.displayName || "",
    userData.email || "",
    userData.userPublic?.displayName || "",
    emailBody,
    goalDay.sharedAt || goalDay.createdAt || ""
  );

  return (
    <Stack
      id="GoalManagement/GoalDayDetail"
      borderBottom={`1px solid ${Color.LightestGray}`}
      sx={{ ...props.containerStyles }}
    >
      <Stack
        id="GoalManagement/GoalDayDetail/GoalDay"
        sx={{ gap: "20px", padding: "20px 20px 0 20px", overflowY: "auto" }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          id="GoalManagement/GoalDayDetail/GoalDay/Header"
        >
          <Typography variant="h5" color={Color.DarkGray}>
            {`${userData.userPublic?.displayName}'s Goals for ${format(
              new Date(goalDay.dayStartUtc),
              "EEEE, MMMM do"
            )}`}
          </Typography>
          <Typography
            variant="caption"
            sx={{ whiteSpace: "nowrap", ml: "30px", mb: "10px" }}
          >
            {format(new Date(goalDay.createdAt), "EEEE, MMM d, yyyy, h:mm a")}
          </Typography>
        </Stack>
        {goals && (
          <Stack
            alignItems="flex-start"
            id="GoalManagement/GoalDayDetail/GoalDay/Goals"
          >
            {goals.map((goal, idx) => renderGoal(goal as Goal, idx))}
          </Stack>
        )}
      </Stack>
      <Paper
        component={Stack}
        sx={{ boxShadow: boxShadowTop }}
        square
        direction="row"
        padding="15px 20px"
        justifyContent="space-between"
        alignItems="center"
        id="GoalManagement/GoalDayDetail/GoalDay/Footer"
        marginTop="auto"
        position="sticky"
      >
        <Stack
          direction="row"
          spacing={1}
          id="GoalManagement/GoalDayDetail/GoalDay/Footer/Status"
        >
          {lastCheckIn && done && (
            <>
              <CheckCircleRoundedIcon color="success" />
              <Typography color={Color.DarkGreen} fontWeight={600}>
                {lastCheckIn.hostPublic?.displayName} checked in{" "}
                {format(
                  new Date(lastCheckIn.updatedAt || lastCheckIn.createdAt),
                  "EEEE, MMMM do"
                )}
              </Typography>
            </>
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          id="GoalManagement/GoalDayDetail/Footer/Actions"
        >
          <Tooltip title="Reply in email">
            <Button
              href={mailToLink}
              onClick={handleCheckInChange}
              rel="noreferrer noopener"
              variant="contained"
              aria-label="reply in email"
              endIcon={<ReplyIcon />}
            >
              Reply
            </Button>
          </Tooltip>
          <Tooltip title={`Mark ${done ? `undone` : `done`}`}>
            <Button onClick={handleCheckInChange} sx={{ width: 120 }}>
              Mark {done ? `undone` : `done`}
            </Button>
          </Tooltip>
        </Stack>
      </Paper>
    </Stack>
  );
}
