import { ApolloClient, gql } from "@apollo/client";
import {
  createGoalDayHostCheckIn,
  CreateGoalDayHostCheckInMutation,
  CreateGoalDayHostCheckInMutationVariables,
  GoalDayHostCheckIn,
  listGoalDayHostCheckInsByGoalDayId,
  ListGoalDayHostCheckInsByGoalDayIdQuery,
  ListGoalDayHostCheckInsByGoalDayIdQueryVariables,
} from "@focusedspace/gql-api";

export const getLatest = (checkIns?: GoalDayHostCheckIn[]) => {
  return checkIns ? checkIns.at(-1) : undefined;
};
export const getGoalDayHostCheckInByGoalDayId = async (
  client: ApolloClient<object>,
  goalDayId: string
) => {
  const variables: ListGoalDayHostCheckInsByGoalDayIdQueryVariables = {
    goalDayId,
  };
  const res = await client.query<ListGoalDayHostCheckInsByGoalDayIdQuery>({
    query: gql(listGoalDayHostCheckInsByGoalDayId),
    variables,
  });

  if (res.error) {
    throw res.error;
  }
  if (res.data) {
    const data = res.data;
    if (data.listGoalDayHostCheckInsByGoalDayId) {
      if (data.listGoalDayHostCheckInsByGoalDayId.items) {
        return data.listGoalDayHostCheckInsByGoalDayId
          .items as GoalDayHostCheckIn[];
      } else return [] as GoalDayHostCheckIn[];
    }
  }
};

export const getGoalDayHostCheckIns = async (
  client: ApolloClient<object>,
  goalDaysIds: string[]
) => {
  const promises = goalDaysIds.map((id) =>
    getGoalDayHostCheckInByGoalDayId(client, id)
  );
  const resolved = await Promise.all(promises);

  return new Map(
    resolved.map((checkIn) => [
      checkIn && checkIn.length > 0 && checkIn[0].goalDayId,
      checkIn,
    ])
  );
};

export const getGoalDayCheckIns = async (
  client: ApolloClient<object>,
  goalDaysIds: string[]
) => {
  const promises = goalDaysIds.map((id) =>
    getGoalDayHostCheckInByGoalDayId(client, id)
  );
  const resolved = await Promise.all(promises);

  return resolved;
};

export const createHostCheckIn = async (
  client: ApolloClient<object>,
  goalDayId: string,
  hostId: string,
  done: boolean
) => {
  const variables: CreateGoalDayHostCheckInMutationVariables = {
    input: {
      goalDayId,
      hostId,
      done,
    },
  };

  const res = await client.mutate<CreateGoalDayHostCheckInMutation>({
    mutation: gql(createGoalDayHostCheckIn),
    variables: variables,
    refetchQueries: [
      {
        query: gql(listGoalDayHostCheckInsByGoalDayId),
        variables: { goalDayId },
      },
    ],
  });
  if (res.errors) {
    throw res.errors;
  }

  return res?.data?.createGoalDayHostCheckIn;
};
