import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import {
  getUserPrivate,
  getUserPublic,
  GetUserPublicQuery,
  UserPrivate,
  UserPublic,
  GetUserPrivateQuery,
  GetUserPrivateQueryVariables,
} from "@focusedspace/gql-api";
import { ApolloClient, gql } from "@apollo/client";

export interface FocusedUser {
  public: UserPublic;
  private: UserPrivate;
}

export interface UserUpdateValues {
  id: string;
  displayName: string;
  location: string;
}

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const getUserPrivateById = async (
  client: ApolloClient<object>,
  userId: string
) => {
  const variables: GetUserPrivateQueryVariables = {
    id: userId,
  };
  const res = await client.query<GetUserPrivateQuery>({
    query: gql(getUserPrivate),
    variables: variables,
  });
  if (res.error) {
    throw res.error;
  }
  if (res.data) {
    const data = res.data;
    if (data.getUserPrivate) {
      return data.getUserPrivate as UserPrivate;
    }
  }
  throw new Error("No user found");
};

export const getUserPrivateByIds = async (
  client: ApolloClient<object>,
  userIds: string[]
) => {
  const userIdPromises = userIds.map((userId) =>
    getUserPrivateById(client, userId)
  );
  const userIdsResolved = await Promise.all(userIdPromises);

  return new Map(userIdsResolved.map((user) => [user.id, user]));
};

export async function getFullUserFromDb(
  userId: string,
  retries: number = 3
): Promise<FocusedUser> {
  console.debug(`Loading pub/priv user record with ID ${userId}`);

  let userPublic: UserPublic | undefined;
  let userPrivate: UserPrivate | undefined;

  for (let x = 0; x < retries; x++) {
    const [respPublic, respPrivate] = await Promise.all([
      API.graphql(graphqlOperation(getUserPublic, { id: userId })),
      API.graphql(graphqlOperation(getUserPrivate, { id: userId })),
    ]);

    console.debug(
      `Received responses: pub ${JSON.stringify(
        respPublic
      )} priv ${JSON.stringify(respPrivate)}`
    );

    userPublic =
      ((respPublic as GraphQLResult<GetUserPublicQuery>).data as GetUserPublicQuery)
        .getUserPublic ?? undefined;
    userPrivate =
      ((respPrivate as GraphQLResult<GetUserPrivateQuery>).data as GetUserPrivateQuery)
        .getUserPrivate ?? undefined;

    if (userPublic && userPrivate) {
      break;
    }

    await sleep(500 * (x + 1));
  }

  if (!userPublic || !userPrivate) {
    console.error(
      `Unable to retrieve full user record: pub ${userPublic} priv ${userPrivate}`
    );
    throw Error("Unable to load user record.");
  }

  return { public: userPublic, private: userPrivate } as FocusedUser;
}
