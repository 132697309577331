import { createTheme } from "@mui/material";
import { Color } from "./SharedStyles";

export const FocusedTheme = createTheme({
  typography: {
    fontFamily: ["League Spartan", "sans-serif"].join(","),
    h1: {
      fontWeight: 700,
      fontSize: "3rem",
    },
    htmlFontSize: 16,
    h2: {
      fontWeight: 700,
      fontSize: "2rem",
    },
    h3: {
      fontWeight: 600,
      lineHeight: 1.21,
      fontSize: "1.7rem",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.4rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: 2,
    },
    body1: {
      fontSize: "1.1rem",
      fontWeight: 300,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "1.1rem",
      fontWeight: 300,
      lineHeight: 1.3,
    },
    button: {
      fontSize: 16,
      fontWeight: 400,
    },
    caption: {
      fontSize: 14,
      fontWeight: 400,
      color: Color.LightGray,
    },
    subtitle1: {
      lineHeight: 1.33,
      color: Color.Gray,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: Color.Purple,
      light: Color.LightPurple,
      dark: Color.DarkPurple,
      contrastText: Color.White,
    },
    secondary: {
      main: Color.Green,
      contrastText: Color.Purple,
    },
    text: {
      primary: Color.DarkGray,
      secondary: Color.Gray,
      disabled: Color.LightGray,
    },
    info: {
      main: Color.Blue,
    },
    success: {
      main: Color.DarkGreen,
      contrastText: Color.White,
    },
    error: {
      main: Color.Red,
      contrastText: Color.White,
    },
    divider: Color.Gray,
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&:before, &:after, &:hover:not(.Mui-disabled, .Mui-error):before, &.Mui-focused:after":
            { borderBottom: "none" },
          borderRadius: 100,
          padding: "10px 25px",
          backgroundColor: Color.LightestPurple,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            color: Color.Purple,
            fontWeight: 900,
          },
        },
      },
    },
  },
});
