import React, { FC, useMemo, useState } from "react";
import {
  Stack,
  Typography,
} from "@mui/material";
import {
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { Color } from "../styles/SharedStyles";

export interface DateRangePickerProps {
  startValue: Date;
  endValue: Date;
  onStartValueChange: (val: Date) => void;
  onEndValueChange: (val: Date) => void;
}

type DateRangeElement = "start" | "end";

const DateRangePicker: FC<DateRangePickerProps> = ({
  startValue,
  endValue,
  onStartValueChange,
  onEndValueChange,
}) => {
  const [error, setError] = useState<DateValidationError | null>(null);

  const handleDateRangeChange = (
    val: Date | null,
    context: PickerChangeHandlerContext<DateValidationError>,
    type: DateRangeElement
  ) => {
    if (!context.validationError) {
      if (val) {
        switch (type) {
          case "start":
            onStartValueChange(val);
            break;
          case "end":
            onEndValueChange(val);
            break;
        }
      }
      if (!val) {
        setError("invalidDate");
      }
    }
  };
  console.log(error);
  const errorMessage = useMemo(() => {
    switch (error) {
      case "maxDate":
      case "minDate": {
        return "Start date must be before end date";
      }

      case "invalidDate": {
        return "Please specify a start and end date";
      }

      default: {
        return "";
      }
    }
  }, [error]);

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ input: { height: 11 } }}
      >
        <DatePicker
          label="Start"
          maxDate={endValue}
          value={startValue}
          onChange={(
            val: Date | null,
            context: PickerChangeHandlerContext<DateValidationError>
          ) => handleDateRangeChange(val, context, "start")}
          onError={(error) => setError(error)}
        />
        -
        <DatePicker
          label="End"
          minDate={startValue}
          value={endValue}
          onChange={(
            val: Date | null,
            context: PickerChangeHandlerContext<DateValidationError>
          ) => handleDateRangeChange(val, context, "end")}
          onError={(error) => setError(error)}
        />
      </Stack>
      {error && errorMessage && (
        <Typography variant="caption" color={Color.Red}>
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default DateRangePicker;
