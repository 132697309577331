import { ApolloClient, gql } from "@apollo/client";
import {
  GoalDay,
  ListGoalDaysQuery,
  ModelGoalDayFilterInput,
  UserPrivate,
} from "@focusedspace/gql-api";
import { GoalDayAndUserData } from "../types";
import { getGoalDayHostCheckIns } from "./CheckIns";
import { getUserPrivateByIds } from "./Users";

const LIST_GOAL_DAYS_WITH_GOALS_QUERY = `
  query ListGoalDaysWithGoals(
    $filter: ModelGoalDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
  listGoalDays(
    limit: $limit,
    nextToken: $nextToken,
    filter: $filter) {
    items {
      dayStartUtc
      createdAt
      userId
      id
      sharedAt
      notes
      goals {
        items {
          description
          notes
          estimateUnits
          estimateValue
          firstStep
          minutesRequired
        }
      }
    }
    nextToken
  }
}
`;

export type GoalDaysAndUserPrivateData = {
  goalDays: GoalDay[];
  userPrivateData: Map<string, UserPrivate>;
};

export type GoalDayAndUserDataVariables = {
  limit: number | null;
  nextToken?: string | null;
  filter: ModelGoalDayFilterInput;
};

export const getGoalDaysGoalsAndUserData = async (
  client: ApolloClient<object>,
  sharedHostId: string,
  dateRange: Array<string | null>
): Promise<GoalDayAndUserData[]> => {
  const limit = 1000;

  var nextToken: string | null | undefined = null;
  const goalDays: GoalDay[] = [];

  do {
    const variables: GoalDayAndUserDataVariables = {
      limit: limit,
      nextToken: nextToken,
      filter: {
        sharedHostId: {
          eq: sharedHostId,
        },
        dayStartUtc: {
          between: dateRange,
        },
      },
    };
    const res = await client.query<ListGoalDaysQuery>({
      query: gql(LIST_GOAL_DAYS_WITH_GOALS_QUERY),
      variables: variables,
    });
    if (res.error) {
      throw res.error;
    }
    if (res.data) {
      const data = res.data;
      if (data.listGoalDays) {
        const listGoalDays = data.listGoalDays;
        if (listGoalDays.items) {
          listGoalDays.items.forEach((item) => {
            if (item) {
              goalDays.push(item as GoalDay);
            }
          });
        }

        nextToken = listGoalDays.nextToken;
      }
    }
  } while (nextToken);

  if (goalDays.length === 0) {
    return [];
  }

  const sharedGds = goalDays.filter((goalDay) => goalDay.sharedAt);

  const userPrivateData = await getUserPrivateByIds(
    client,
    sharedGds.map((goal) => goal.userId)
  );

  const checkInStatusData = await getGoalDayHostCheckIns(
    client,
    sharedGds.map((goalDay) => goalDay.id)
  );

  const retArr: GoalDayAndUserData[] = [];
  sharedGds.forEach((goalDay) => {
    const userData = userPrivateData.get(goalDay.userId) || ({} as UserPrivate);
    const checkInData = checkInStatusData.get(goalDay.id);

    retArr.push({ goalDay, userData, checkInData });
  });

  const sorted = [...retArr];
  sorted.sort(
    (a, b) => -a.goalDay.sharedAt!.localeCompare(b.goalDay.sharedAt!)
  );

  return sorted;
};
