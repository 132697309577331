import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Header, APP_BAR_HEIGHT } from "../components/Header";
import { GoalDetail } from "../components/GoalDetail";
import { HostNotes } from "../components/HostNotes";
import { Box, Skeleton, Stack } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import { useApolloClient } from "@apollo/client";
import { getGoalDaysGoalsAndUserData } from "../hooks/Goals";
import { MemberDetail } from "../components/MemberDetail";
import { DateRange, GoalDayAndUserData } from "../types";
import { useAuthenticatedUserState } from "../providers/AuthenticatedUserProvider";
import { Color } from "../styles/SharedStyles";
import { createHostCheckIn, getLatest } from "../hooks/CheckIns";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { GoalDaysList } from "../components/GoalDaysList";
import { GoalDayHostCheckIn } from "@focusedspace/gql-api";
import { GoalDayRow, createRowData } from "../components/GoalDayTable";

const endOfToday = endOfDay(new Date());
const startOfyesterday = startOfDay(subDays(endOfToday, 1));
const defaultTimeRange: DateRange = {
  start: startOfyesterday,
  end: endOfToday,
};

export const GoalManagement = () => {
  const apolloClient = useApolloClient();
  const { focusedUser } = useAuthenticatedUserState();

  const [goalDays, setGoalDays] = useState<GoalDayAndUserData[]>();

  const [selectedGoalDay, setSelectedGoalDay] = useState<GoalDayAndUserData>();
  const [selectedIdx, setSelectedIdx] = useState<number>(0);

  // GoalsList state
  const [dateRange, setDateRange] = useState<DateRange>();
  const [tableData, setTableData] = useState<GoalDayRow[]>();
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState("All");
  const [listSearch, setListSearch] = useState<string>("");

  const dateRangeToFetchParam = (
    dateRange: DateRange
  ): Array<string | null> => {
    return [dateRange.start.toISOString(), dateRange.end.toISOString()];
  };

  const retrieveGoalDays = useCallback(
    (hostId: string, range: DateRange) => {
      setListLoading(true);
      getGoalDaysGoalsAndUserData(
        apolloClient,
        hostId,
        dateRangeToFetchParam(range)
      )
        .then((goalDays) => {
          setGoalDays(goalDays);
          setSelectedGoalDay(goalDays[selectedIdx]);
          setListLoading(false);
        })
        .catch((error) => {
          console.log("Error retrieving goal days", error);
        });
    },
    [apolloClient, selectedIdx]
  );

  // Initial data fetch and list data creation
  useEffect(() => {
    if (focusedUser) {
      retrieveGoalDays(focusedUser.private.id, dateRange || defaultTimeRange);
    }
  }, [focusedUser, retrieveGoalDays, dateRange]);

  useEffect(() => {
    if (goalDays) {
      const rows = goalDays.map(({ goalDay, userData, checkInData }) =>
        createRowData(goalDay, userData, checkInData)
      );
      setTableData(rows);
      setListLoading(false);
    }
  }, [goalDays]);

  const handleCheckInChange = (done: boolean) => {
    if (selectedGoalDay && focusedUser) {
      createHostCheckIn(
        apolloClient,
        selectedGoalDay?.goalDay.id,
        focusedUser.private.id,
        done
      ).then((value) => {
        if (goalDays) {
          let newGoalDays: GoalDayAndUserData[] = [...goalDays];
          newGoalDays[selectedIdx].checkInData = [value as GoalDayHostCheckIn];
          setSelectedGoalDay(newGoalDays[selectedIdx]);
          setGoalDays(newGoalDays);
        }
      });
    }
  };

  const handleGoalSelect = (rowIdx: number, goalDayId: string) => {
    if (goalDays) {
      const selected = goalDays.find(({ goalDay }) => goalDay.id === goalDayId);
      setSelectedIdx(rowIdx);
      setSelectedGoalDay(selected);
    }
  };

  const handleStartDateChange = useCallback(
    (date: Date) => {
      let newRange = dateRange || defaultTimeRange;
      setDateRange({ ...newRange, start: date });
    },
    [dateRange]
  );

  const handleEndDateChange = useCallback(
    (date: Date) => {
      let newRange = dateRange || defaultTimeRange;
      setDateRange({ ...newRange, end: date });
    },
    [dateRange]
  );

  const handleStatusFilterChange = useCallback(
    (e: SelectChangeEvent) => {
      setStatusFilter(e.target.value);
    },
    [setStatusFilter]
  );

  const handleListSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setListSearch(e.target.value);
    },
    [setListSearch]
  );

  return (
    <Stack height="100vh" id="PageContainer" sx={{ overflowY: "hidden" }}>
      <Header />
      {listLoading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: ".5rem" }} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
        </Stack>
      ) : (
        <Box
          id="PageBodyContainer"
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridTemplateRows="repeat(2, 50%)"
          sx={{
            height: `calc(100vh - ${APP_BAR_HEIGHT})`,
            overflowY: "hidden",
          }}
        >
          {tableData && (
            <GoalDaysList
              rows={tableData}
              currentIdx={selectedIdx}
              onGoalSelect={handleGoalSelect}
              listSearch={listSearch}
              onListSearch={handleListSearchChange}
              statusFilter={statusFilter}
              onStatusFilterChange={handleStatusFilterChange}
              rangeStart={dateRange?.start || defaultTimeRange.start}
              rangeEnd={dateRange?.end || defaultTimeRange.end}
              onStartValueChange={handleStartDateChange}
              onEndValueChange={handleEndDateChange}
              containerStyles={{
                borderRight: `1px solid ${Color.LightestGray}`,
                gridColumn: "1 / span 5",
                gridRow: "1 / 4",
                height: "inherit",
              }}
            />
          )}
          {selectedGoalDay && focusedUser && (
            <>
              <GoalDetail
                key={selectedGoalDay.goalDay.id}
                goalDay={selectedGoalDay.goalDay}
                hostData={focusedUser}
                userData={selectedGoalDay.userData}
                lastCheckIn={getLatest(selectedGoalDay?.checkInData)}
                onCheckInChange={handleCheckInChange}
                containerStyles={{
                  gridColumn: "6 / span 7",
                  gridRow: "1 / 1",
                }}
              />
              <MemberDetail
                key={selectedGoalDay?.userData.id}
                user={selectedGoalDay?.userData}
                containerStyles={{
                  gridColumn: "6 / span 2",
                  gridRow: "2 / 2",
                  height: "inherit",
                }}
              />
              <HostNotes
                currentUser={selectedGoalDay?.userData}
                hostId={focusedUser.private.id}
                containerStyles={{
                  gridColumn: "8 / span 6",
                  gridRow: "2 / 2",
                }}
              />
            </>
          )}
        </Box>
      )}
    </Stack>
  );
};
