import { CSSProperties, ChangeEventHandler, FC } from "react";
import {
  Typography,
  Stack,
  InputAdornment,
  FormControl,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  Divider,
  Grid,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { SelectChangeEventType, SharedGoalResponseStatus } from "../types";
import { Color } from "../styles/SharedStyles";
import { GoalDayRow, GoalDayTable } from "./GoalDayTable";
import DateRangePicker from "./DateRangePicker";
import { APP_BAR_HEIGHT } from "./Header";

interface GoalDaysListProps {
  listSearch?: string;
  onListSearch: ChangeEventHandler<HTMLInputElement>;
  statusFilter: string;
  onStatusFilterChange: SelectChangeEventType;
  onGoalSelect: (rowIdx: number, goalDayId: string) => void;
  currentIdx: number;
  rows: GoalDayRow[];
  containerStyles?: CSSProperties;
  rangeStart: Date;
  rangeEnd: Date;
  onStartValueChange: (val: Date) => void;
  onEndValueChange: (val: Date) => void;
}

export const GoalDaysList: FC<GoalDaysListProps> = ({
  listSearch = "",
  onListSearch,
  statusFilter,
  onStatusFilterChange,
  rangeStart,
  rangeEnd,
  onGoalSelect,
  currentIdx,
  rows,
  containerStyles,
  onStartValueChange,
  onEndValueChange,
}) => {
  let tableRows = rows;
  if (listSearch) {
    tableRows = tableRows?.filter(({ memberName }) => {
      return memberName.toLowerCase().includes(listSearch.toLowerCase());
    });
  }

  if (statusFilter !== "All") {
    tableRows = tableRows.filter(({ status }) => {
      return status === statusFilter;
    });
  }

  const totalCount = tableRows.length;
  let unreadCount = 0;
  let overdueCount = 0;
  tableRows.forEach((element) => {
    if (element.status === SharedGoalResponseStatus.Unread) {
      unreadCount++;
    }
    if (element.status === SharedGoalResponseStatus.Overdue) {
      overdueCount++;
    }
  });

  const Filters = () => {
    return (
      <Grid
        container
        display="grid"
        gridTemplateColumns="1fr 2fr"
        gridTemplateRows="1fr 1fr"
        width="100%"
        columnGap={5}
        rowGap={3}
        pl="20px"
        pr="20px"
      >
        <Grid item>
          <FormControl size="small">
            <InputLabel>Filter shared goals</InputLabel>
            <Select
              value={statusFilter}
              onChange={onStatusFilterChange}
              sx={{ minWidth: "150px" }}
              label="Filter shared goals"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value={SharedGoalResponseStatus.Unread}>
                Unread
              </MenuItem>
              <MenuItem value={SharedGoalResponseStatus.Done}>Done</MenuItem>
              <MenuItem value={SharedGoalResponseStatus.Overdue}>
                Overdue
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <DateRangePicker
            startValue={rangeStart}
            endValue={rangeEnd}
            onStartValueChange={onStartValueChange}
            onEndValueChange={onEndValueChange}
          />
        </Grid>
        <Grid item gridRow={2} gridColumn="1/ span 2">
          <TextField
            value={listSearch}
            onChange={onListSearch}
            placeholder="Search shared goals"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack
      spacing={3}
      mt="10px"
      sx={containerStyles}
      id="GoalManagement/ListContainer"
    >
      <Stack
        p="20px"
        spacing={1}
        id="GoalManagement/ListContainer/HeadingContainer"
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="h2">Goals shared with me</Typography>
        </Stack>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}
          id="GoalManagement/ListContainer/HeadingContainer/Header/Count"
        >
          <Typography variant="subtitle1">
            {`${totalCount} Shared goals`}
          </Typography>
          <Typography variant="subtitle1">{`${unreadCount} New`}</Typography>
          {overdueCount > 0 && (
            <Typography variant="subtitle1" color={Color.Red} fontWeight={600}>
              {`${overdueCount} Overdue`}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Filters />
      <GoalDayTable
        rows={tableRows}
        selectedIdx={currentIdx}
        onGoalSelect={onGoalSelect}
      />
    </Stack>
  );
};
