import { Link, Stack, Typography } from "@mui/material";
import { Color } from "../styles/SharedStyles";
import { UserPrivate } from "@focusedspace/gql-api";
import { format } from "date-fns";
import { CSSProperties } from "react";

export const MemberDetail = (props: {
  user: UserPrivate;
  containerStyles?: CSSProperties;
}) => {
  const { user } = props;
  return (
    <Stack
      sx={{
        ...props.containerStyles,
        alignItems: "center",
        color: Color.White,
        padding: "20px",
        backgroundColor: Color.Purple,
        height: "inherit",
      }}
    >
      {user.userPublic?.photoUrl && (
        <img
          src={user.userPublic.photoUrl}
          alt="profile"
          style={{ marginBottom: "20px" }}
        />
      )}
      <Typography variant="h5" color={Color.Green} textAlign="center">
        {user.userPublic?.displayName}
      </Typography>
      <Link variant="body2" color={Color.Green} href={`mailto:${user.email}`}>
        {user.email}
      </Link>
      <Typography>
        {user.userPublic?.location} {user.timeZone}
      </Typography>
      <Typography>
        Joined {format(new Date(user.createdAt), "MMMM d, yyyy")}
      </Typography>
      {user.userPublic?.bio && (
        <Stack
          direction="column"
          spacing={2}
          sx={{ margin: "20px" }}
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: "5rem",
              color: Color.Green,
              fontFamily: "Coustard",
              mb: "-4rem",
            }}
          >
            "
          </Typography>
          <Typography>{user.userPublic?.bio}</Typography>
        </Stack>
      )}
    </Stack>
  );
};
