import { FC, ReactNode } from "react";
import { SharedGoalResponseStatus } from "../types";
import {
  GoalDay,
  GoalDayHostCheckIn,
  UserPrivate,
} from "@focusedspace/gql-api/dist/types";
import {
  CSSObject,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { add, differenceInHours, format } from "date-fns";

import { getLatest } from "../hooks/CheckIns";
import { Color } from "../styles/SharedStyles";

const getCheckInDueDate = (shareDate: Date) => {
  return add(shareDate, { hours: 24 });
};
const isOverdue = (shareDate: Date) => {
  return differenceInHours(Date.now(), shareDate) > 24;
};

export const createRowData = (
  goalDay: GoalDay,
  user: UserPrivate,
  checkIns?: GoalDayHostCheckIn[]
) => {
  const goalDayDate = new Date(goalDay.sharedAt || goalDay.createdAt);
  const latestCheckIn = getLatest(checkIns);
  const overdue = isOverdue(goalDayDate);

  let status: SharedGoalResponseStatus = SharedGoalResponseStatus.Unread;
  if (latestCheckIn && latestCheckIn.done === true) {
    status = SharedGoalResponseStatus.Done;
  }
  if ((!latestCheckIn || !latestCheckIn.done) && overdue) {
    status = SharedGoalResponseStatus.Overdue;
  }

  const rows: GoalDayRow = {
    id: goalDay?.id || "",
    dueDate: getCheckInDueDate(goalDayDate),
    memberName: user.userPublic?.displayName || "",
    shareDate: format(goalDayDate, "M/dd/yy h:mm a "),
    statusCell: getStatusCell(status, latestCheckIn),
    status: status,
    lastCheckIn: latestCheckIn,
  };

  return rows;
};

const getStatusCell = (
  status: SharedGoalResponseStatus,
  lastCheckIn?: GoalDayHostCheckIn
) => {
  let content: ReactNode;
  switch (status) {
    case SharedGoalResponseStatus.Done: {
      content = (
        <Stack spacing={1} justifyContent="flex-end" alignItems="flex-end">
          <Chip
            label="Done"
            color="success"
            size="small"
            icon={<CheckRoundedIcon />}
          />
          {lastCheckIn && (
            <Typography variant="caption">
              Checked in at{" "}
              {format(
                new Date(lastCheckIn.updatedAt || lastCheckIn.createdAt),
                "M/dd/yy h:mm a"
              )}{" "}
              by {lastCheckIn.hostPublic?.displayName}
            </Typography>
          )}
        </Stack>
      );
      break;
    }
    case SharedGoalResponseStatus.Overdue: {
      content = <>Overdue</>;
      break;
    }
  }

  return content;
};

const getRowStyles = (status: SharedGoalResponseStatus) => {
  let styles: CSSObject = { minHeight: "120px" };

  switch (status) {
    case SharedGoalResponseStatus.Unread: {
      styles = { ...styles, ">td": { fontWeight: "700" } };
      break;
    }
    case SharedGoalResponseStatus.Overdue: {
      styles = { ...styles, ">td": { fontWeight: "700", color: Color.Red } };
      break;
    }
  }

  return styles;
};

export interface GoalDayRow {
  id: string;
  memberName: string;
  shareDate: string;
  dueDate: Date;
  status: SharedGoalResponseStatus;
  statusCell: ReactNode;
  lastCheckIn?: GoalDayHostCheckIn;
}

interface GoalDayTableProps {
  rows: GoalDayRow[];
  selectedIdx: number;
  onGoalSelect: (rowIdx: number, goalDayId: string) => void;
}
export const GoalDayTable: FC<GoalDayTableProps> = ({
  rows,
  selectedIdx,
  onGoalSelect,
}) => {
  return (
    <TableContainer id="GoalManagement/ListContainer/GoalDaysTableContainer">
      <Table sx={{ width: "100%" }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ paddingLeft: "30px" }}>
              From
            </TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right" sx={{ paddingRight: "30px" }}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflowY: "scroll" }}>
          {rows.map((row, idx) => (
            <TableRow
              id={idx.toString()}
              key={idx}
              selected={selectedIdx === idx}
              onClick={() => onGoalSelect(idx, row.id)}
              sx={getRowStyles(row.status)}
              hover
            >
              <TableCell
                align="left"
                sx={{ minWidth: 150, paddingLeft: "30px" }}
              >
                {row.memberName}
              </TableCell>
              <TableCell align="right">{row.shareDate}</TableCell>
              <TableCell align="right" sx={{ paddingRight: "30px" }}>
                {row.statusCell}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
