import * as React from "react";
import { ThemeProvider } from "@mui/material";
import { GoalManagement } from "./containers/GoalManagement";
import { FocusedTheme } from "./styles/MuiTheme";

import { AuthenticatedUserProvider } from "./providers/AuthenticatedUserProvider";
import { ApolloCognitoProvider } from "./providers/ApolloCognitoProvider";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

Amplify.configure(awsExports);

function App() {
  return (
    <>
      <ThemeProvider theme={FocusedTheme}>
        <AuthenticatedUserProvider>
          <ApolloCognitoProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <GoalManagement />
            </LocalizationProvider>
          </ApolloCognitoProvider>
        </AuthenticatedUserProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
