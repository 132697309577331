import React from "react";

import { Auth } from "aws-amplify";

import { createAuthLink, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import {
  createHttpLink,
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";

import awsExports from "../aws-exports";

export const ApolloCognitoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // setup apollo
  const url = awsExports.aws_appsync_graphqlEndpoint;
  const region = awsExports.aws_appsync_region;
  const auth: AuthOptions = {
    type: "AMAZON_COGNITO_USER_POOLS",
    jwtToken: () =>
      Auth.currentSession().then((s) => s.getIdToken().getJwtToken()),
  };

  const httpLink = createHttpLink({ uri: url });

  const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({ resultCaching: true }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
