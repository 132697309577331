import { gql, useMutation, useQuery } from "@apollo/client";
import {
  CreateUserHostNoteMutation,
  UserHostNote,
  UserHostNotesByUserIdQuery,
  UserPrivate,
  createUserHostNote,
  userHostNotesByUserId,
} from "@focusedspace/gql-api";
import { Add, SearchOutlined } from "@mui/icons-material";
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { CSSProperties, ChangeEvent, useState } from "react";
import { Color, boxShadowTop } from "../styles/SharedStyles";

const NotesList = (props: { notes: UserHostNote[] }) => {
  return (
    <List
      id="GoalManagement/HostNotesContainer/NotesList"
      sx={{ padding: "0 10px 20px", height: "100%", overflowY: "auto" }}
      dense
    >
      {props.notes.map((note) => (
        <ListItem
          key={note.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            borderBottom: `1px solid ${Color.LightestGray}`,
          }}
        >
          <Typography
            variant="caption"
            color={Color.LightGray}
            alignSelf="flex-end"
          >
            {format(new Date(note.createdAt), "M/dd/yy")}{" "}
            {note.hostPublic?.displayName}
          </Typography>
          <ListItemText>{note.note}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export function HostNotes(props: {
  currentUser?: UserPrivate;
  hostId?: string;
  containerStyles?: CSSProperties;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [newNote, setNewNote] = useState<string>();

  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useQuery<UserHostNotesByUserIdQuery>(gql(userHostNotesByUserId), {
    variables: {
      userId: props.currentUser?.id,
    },
  });

  const [createHostNote, { loading: createLoading, error: createError }] =
    useMutation<CreateUserHostNoteMutation>(gql(createUserHostNote), {
      variables: {
        input: {
          userId: props.currentUser?.id,
          hostId: props.hostId,
          note: newNote,
        },
      },
      refetchQueries: ["UserHostNotesByUserId"],
    });

  const handleChangeNewNote = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e);
    setNewNote(e.target.value);
  };

  const handleSaveNote = () => {
    if (newNote) {
      createHostNote();
      setNewNote("");
    }
  };

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const notes = listData?.userHostNotesByUserId?.items as UserHostNote[];
  let sortedNotes;
  if (notes) {
    sortedNotes = [...notes];
    sortedNotes = sortedNotes.sort(
      (a, b) => -a.createdAt!.localeCompare(b.createdAt!)
    );
  }

  if (searchTerm) {
    sortedNotes = sortedNotes?.filter(({ note }) => {
      return note.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  return (
    <>
      {listLoading && <LinearProgress />}
      {listError && (
        <Typography variant="h6">
          An error occurred while loading host notes: {listError.message}
        </Typography>
      )}
      {sortedNotes && (
        <Stack
          id="GoalManagement/HostNotesContainer"
          sx={{ ...props.containerStyles }}
        >
          <Stack
            id="GoalManagement/HostNotesContainer/Header"
            direction="row"
            sx={{
              padding: "10px 20px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Host notes</Typography>
            <FormControl size="small">
              <OutlinedInput
                value={searchTerm}
                onChange={handleSearchTermChange}
                placeholder="Search host notes"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchOutlined />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Stack>

          <NotesList notes={sortedNotes} />
          {createLoading ? (
            <LinearProgress />
          ) : (
            <Paper
              id="GoalManagement/HostNotesContainer/CreateNote"
              component={Stack}
              sx={{
                boxShadow: boxShadowTop,
              }}
              square
              direction="row"
              spacing={2}
              marginTop="auto"
              position="sticky"
              alignItems="center"
              padding="15px 20px"
            >
              <FilledInput
                placeholder="Add a new note"
                value={newNote}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeNewNote(e)
                }
                multiline
                fullWidth
                size="small"
              />
              <IconButton
                onClick={handleSaveNote}
                color="secondary"
                sx={{ bgcolor: Color.Purple, height: "40px", width: "40px" }}
              >
                <Add />
              </IconButton>
            </Paper>
          )}
          {createError && (
            <Typography variant="h6">
              An error occurred while adding note: {createError.message}
            </Typography>
          )}
        </Stack>
      )}
    </>
  );
}
