import React from "react";
import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Face from "@mui/icons-material/Face";
import { useAuthenticatedUserState } from "../providers/AuthenticatedUserProvider";
import { Color } from "../styles/SharedStyles";

export const APP_BAR_HEIGHT = "50px";

export const Header = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const { focusedUser, signOut } = useAuthenticatedUserState();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar
      position="static"
      sx={{
        flexDirection: "row",
        p: "10px 20px",
        boxShadow: "none",
        height: APP_BAR_HEIGHT,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">focused.</Typography>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="User settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            {focusedUser?.public.photoUrl ? (
              <Avatar src={focusedUser?.public.photoUrl} />
            ) : (
              <Face color="secondary" />
            )}
            <Typography
              variant={"h6"}
              sx={{ marginLeft: "5px", color: `${Color.Green}` }}
            >
              {focusedUser?.public.displayName}
            </Typography>
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem
            key="sign-out"
            onClick={() => {
              signOut();
              handleCloseUserMenu();
            }}
          >
            <Typography textAlign="center">Sign Out</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </AppBar>
  );
};
