import { ApolloError } from "@apollo/client";
import { GoalDay, GoalDayHostCheckIn, UserPrivate } from "@focusedspace/gql-api";
import { SelectChangeEvent } from "@mui/material";
import { ReactNode } from "react";

export type ReturnStatusType = { data: any; loading: boolean; error: ApolloError | undefined };
export interface GoalDayAndUserData {
  goalDay: GoalDay;
  userData: UserPrivate;
  checkInData?: GoalDayHostCheckIn[];
}

export enum SharedGoalResponseStatus {
  Unread = "unread",
  Done = "done",
  Overdue = "overdue",
}

export type SelectChangeEventType = (
  event: SelectChangeEvent<string>,
  child: ReactNode
) => void;
export interface DateRange {
  start: Date;
  end: Date;
}
